import aboutImage from '../graphics/RISTabout.jpg'

import logoA from '../graphics/brand/RIST-logo_02.png'
import logoB from '../graphics/brand/ThinkDigitalVRWhite.png'
import logoC from '../graphics/brand/RIST-logo_02.png'


// Think Digital
export const Theme = {
    primary: '#002E88',
    primaryHover: '#31577D',
    alternative: '#23ffe3',
    light: '#ffff',
    darkerLight: '#f8f8f8',
    dark: '#272838',
    gray: '#969696',
    medium: '#4d4d4d',

    // roundCorners: '.5em',
    // rounderCorners: '1.5em',
    roundCorners: '.1em',
    rounderCorners: '.5em',
    shadow: '0 .1em .2em rgb(1,1,1,.2)',

    mobile: '600px',
    tablet: '1138px',
}

export const brand = {
    getTheAppFirstDescription: `For a truly immersive experience, download the RISTVR app in your Oculus GO VR headset`,
    getTheAppSecondDescription: `Immerse yourself in the RISTVR library, using your mobile device. Compatible with Google Cardboard, but does not require a VR headset. `,
    getTheAppShortDescription: `A collection of immersive agricultural experiences!`,

    manifesto: 'Rural Industries Skill Training (RIST) is recognised as one of Australia’s leading independent agricultural colleges. This immersive education platform is designed to inspire, engage and educate students to learn more about food & fibre, and pursue a career in agriculture.',
    aboutImage: aboutImage,
    textLogo: 'RIST',

    logoA: logoA,
    logoB: logoB,
    logoC: logoC,

    title: 'Rist VR',
    defaultAuthor: '1553600127858',

    // Links
    webUrl: 'https://rist.edu.au/',
    about: 'https://rist.edu.au/about-rist',
    emailUrl: 'info@rist.edu.au',
    privacyPolicy: 'https://rist.edu.au/policies-student',
    contactUs: 'https://rist.edu.au/contact-1',

    oculusQuestLink: '',
    oculusLink: '',
    oculusGoLink: 'https://www.oculus.com/experiences/go/2347292628650005/',
    viveLink: '',
    appStoreLink: 'https://apps.apple.com/au/app/ristvr/id1506168490',
    googlePlayLink: 'https://play.google.com/store/apps/details?id=com.ThinkDigitalStudios.RISTmobile',
}

export const firebaseConfig = {
    apiKey: "AIzaSyB0dFZDZzV0B1RQU2eOeJ5LfMX-NPeWBiQ",
    authDomain: "ristplayer.firebaseapp.com",
    databaseURL: "https://ristplayer.firebaseio.com",
    projectId: "ristplayer",
    storageBucket: "gs://farmvr-ristplayer",
    messagingSenderId: "485777670070",
    appId: "1:485777670070:web:31ad51749534ff46"
};

export const instructureData = undefined